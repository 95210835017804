:root {
    --main-top-padding: 14rem;
    --main-bottom-padding: 14rem;

    --main-side-padding: 6.4rem;
    --main-right-padding: 6.4rem;
    --main-left-padding: 6.4rem;

    --gutter: 8rem;
}

/* max-width: 1200px */
@media (max-width: 1200px) {
    :root {
        --main-top-padding: 12rem;
        --main-bottom-padding: 12rem;

        --gutter: 5rem;
    }
}

/* max-width: 992px */
@media (max-width: 992px) {
    :root {
        --main-top-padding: 10rem;
        --main-bottom-padding: 10rem;

        --gutter: 4rem;
    }
}

/* max-width: 820px */
@media (max-width: 820px) {
    :root {
        --main-top-padding: 8rem;
        --main-bottom-padding: 8rem;

        --main-side-padding: 6.6rem;
        --main-right-padding: 6.6rem;
        --main-left-padding: 6.6rem;

        --gutter: 3rem;
    }
}

/* max-width: 600px */
@media (max-width: 600px) {
    :root {
        --main-top-padding: 6rem;
        --main-bottom-padding: 6rem;

        --main-side-padding: 3.2rem;
        --main-right-padding: 5.6rem;
        --main-left-padding: 3.2rem;
    }
}

/* max-width: 400px */
@media (max-width: 400px) {
    :root {
        --main-top-padding: 4rem;
        --main-bottom-padding: 4rem;

        --main-side-padding: 1.6rem;
        --main-right-padding: 4.8rem;
        --main-left-padding: 1.6rem;
    }
}
