/* ------ Aassistant ------*/
/* assistant-300 - latin */
@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/Assistant/Assistant-Light.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Assistant/Assistant-Light.ttf') format('truetype'); /* Modern Browsers */
}
/* assistant-400 - latin */
@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/Assistant/Assistant-Regular.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Assistant/Assistant-Regular.ttf') format('truetype'); /* Modern Browsers */
}
/* assistant-500 - latin */
@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/Assistant/Assistant-Medium.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Assistant/Assistant-Medium.ttf') format('truetype'); /* Modern Browsers */
}
/* assistant-600 - latin */
@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 600;
    src: url('../assets/fonts/Assistant/Assistant-SemiBold.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Assistant/Assistant-SemiBold.ttf') format('truetype'); /* Modern Browsers */
}

/* ------ Noto Sans ------*/
/* NotoSans-300 - latin */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/NotoSans/NotoSans-Light.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSans/NotoSans-Light.ttf') format('truetype'); /* Modern Browsers */
}
/* NotoSans-400 - latin */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/NotoSans/NotoSans-Regular.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSans/NotoSans-Regular.ttf') format('truetype'); /* Modern Browsers */
}
/* NotoSans-500 - latin */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/NotoSans/NotoSans-Medium.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSans/NotoSans-Medium.ttf') format('truetype'); /* Modern Browsers */
}
/* NotoSans-600 - latin */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../assets/fonts/NotoSans/NotoSans-SemiBold.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSans/NotoSans-SemiBold.ttf') format('truetype'); /* Modern Browsers */
}

/* NotoSans-700 - latin */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/NotoSans/NotoSans-Bold.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSans/NotoSans-Bold.ttf') format('truetype'); /* Modern Browsers */
}

/* ------ Noto Sans Condensed------*/
/* NotoSansCondensed-300 - latin */
@font-face {
    font-family: 'Noto Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/NotoSans/NotoSans_Condensed-Light.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSans/NotoSans_Condensed-Light.ttf') format('truetype'); /* Modern Browsers */
}
/* NotoSansCondensed-400 - latin */
@font-face {
    font-family: 'Noto Sans Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/NotoSans/NotoSans_Condensed-Regular.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSans/NotoSans_Condensed-Regular.ttf') format('truetype'); /* Modern Browsers */
}
/* NotoSansCondensed-500 - latin */
@font-face {
    font-family: 'Noto Sans Condensed';
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/NotoSans/NotoSans_Condensed-Medium.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSans/NotoSans_Condensed-Medium.ttf') format('truetype'); /* Modern Browsers */
}
/* NotoSansCondensed-600 - latin */
@font-face {
    font-family: 'Noto Sans Condensed';
    font-style: normal;
    font-weight: 600;
    src: url('../assets/fonts/NotoSans/NotoSans_Condensed-SemiBold.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSans/NotoSans_Condensed-SemiBold.ttf') format('truetype'); /* Modern Browsers */
}

/* NotoSansCondensed-700 - latin */
@font-face {
    font-family: 'Noto Sans Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/NotoSans/NotoSans_Condensed-Bold.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSans/NotoSans_Condensed-Bold.ttf') format('truetype'); /* Modern Browsers */
}

/* ------ Noto Sans TC ------*/
/* NotoSansTC-300 - latin */
@font-face {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/NotoSansTC/NotoSansTC-Light.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSansTC/NotoSansTC-Light.ttf') format('truetype'); /* Modern Browsers */
}
/* NotoSansTC-400 - latin */
@font-face {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/NotoSansTC/NotoSansTC-Regular.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSansTC/NotoSansTC-Regular.ttf') format('truetype'); /* Modern Browsers */
}
/* NotoSansTC-500 - latin */
@font-face {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/NotoSansTC/NotoSansTC-Medium.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSansTC/NotoSansTC-Medium.ttf') format('truetype'); /* Modern Browsers */
}
/* NotoSansTC-600 - latin */
@font-face {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 600;
    src: url('../assets/fonts/NotoSansTC/NotoSansTC-SemiBold.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSansTC/NotoSansTC-SemiBold.ttf') format('truetype'); /* Modern Browsers */
}

/* NotoSansTC-700 - latin */
@font-face {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/NotoSansTC/NotoSansTC-Bold.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/NotoSansTC/NotoSansTC-Bold.ttf') format('truetype'); /* Modern Browsers */
}

/* ------Roboto ------*/
/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Roboto/Roboto-Light.ttf') format('truetype'); /* Modern Browsers */
}
/* roboto-400 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype'); /* Modern Browsers */
}
/* roboto-400 - italic */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('../assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype'); /* Modern Browsers */
}
/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype'); /* Modern Browsers */
}

/* ------Roboto Mono------*/
/* roboto-mono-300 - latin */
@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/Roboto+Mono/RobotoMono-Light.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Roboto+Mono/RobotoMono-Light.ttf') format('truetype'); /* Modern Browsers */
}
/* roboto-mono-400 - latin */
@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/Roboto+Mono/RobotoMono-Regular.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Roboto+Mono/RobotoMono-Regular.ttf') format('truetype'); /* Modern Browsers */
}
/* roboto-mono-500 - latin */
@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/Roboto+Mono/RobotoMono-Medium.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Roboto+Mono/RobotoMono-Medium.ttf') format('truetype'); /* Modern Browsers */
}
/* roboto-mono-600 - latin */
@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    src: url('../assets/fonts/Roboto+Mono/RobotoMono-SemiBold.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Roboto+Mono/RobotoMono-SemiBold.ttf') format('truetype'); /* Modern Browsers */
}
/* roboto-mono-700 - latin */
@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/Roboto+Mono/RobotoMono-Bold.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Roboto+Mono/RobotoMono-Bold.ttf') format('truetype'); /* Modern Browsers */
}

/* ------ Ubuntu ------*/
/* ubuntu-300 - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/Ubuntu/Ubuntu-Light.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Ubuntu/Ubuntu-Light.ttf') format('truetype'); /* Modern Browsers */
}
/* ubuntu-400 - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype'); /* Modern Browsers */
}
/* ubuntu-400 - italic */
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 400;
    src: url('../assets/fonts/Ubuntu/Ubuntu-Italic.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Ubuntu/Ubuntu-Italic.ttf') format('truetype'); /* Modern Browsers */
}

/* ubuntu-500 - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype'); /* Modern Browsers */
}
/* ubuntu-700 - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype');
    src:
        local(''),
        url('../assets/fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype'); /* Modern Browsers */
}
