*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    font-size: 62.5%;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Roboto', 'Assistant', sans-serif;
    background: #fff;
    color: var(--txt-active);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

body.popup-open {
    overflow: hidden;
}

strong {
    font-weight: 500;
}

ol,
ul,
li {
    list-style: none;
}

a {
    font-family: 'Roboto', 'Assistant', sans-serif;
    color: var(--txt-active);
    cursor: pointer;
    text-decoration: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Conqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    user-select: none;
}

button > * {
    pointer-events: none;
}

button {
    font-family: 'Roboto', 'Assistant', sans-serif;
    cursor: pointer;
    border: none;
    background: none;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none;
    user-select: none; /* supported by Chrome and Opera */
    padding: 0;
    margin: 0;
    color: var(--txt-active);
    /* font-weight: 400; */
    /* font-size: 1.6rem; */
    letter-spacing: 0.1px;
    transition: all 0.3s ease-in-out;
}

button:hover {
    border: none;
    background: none;
}

button:active {
    border: none;
    background: none;
}

img {
    object-fit: contain;
}

video {
    width: 100%;
    display: block;
}
