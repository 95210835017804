:root {
    --primary-50: #f2ffe6;
    --primary-100: #e3fdca;
    --primary-200: #c8fc9a;
    --primary-300: #a3f660;
    --primary-400: #8fed46;
    --primary-500: #62d111;
    --primary-600: #50a00f;
    --primary-700: #387f0c;
    --primary-800: #306410;
    --primary-900: #2a5512;

    --secondary-50: #eff1fe;
    --secondary-100: #e2e4fd;
    --secondary-200: #cbcffa;
    --secondary-300: #acaef5;
    --secondary-400: #8e8aef;
    --secondary-500: #7c6ee6;
    --secondary-600: #6c52d9;
    --secondary-700: #5b41be;
    --secondary-800: #4c399a;
    --secondary-900: #40347b;

    --tertiary-50: #f2fbf9;
    --tertiary-100: #d2f5ee;
    --tertiary-200: #a5eadd;
    --tertiary-300: #70d8c9;
    --tertiary-400: #41beaf;
    --tertiary-500: #29a397;
    --tertiary-600: #1e837b;
    --tertiary-700: #1c6963;
    --tertiary-800: #1b5450;
    --tertiary-900: #1b4643;

    --info-50: #e0f7fa;
    --info-100: #b2ebf2;
    --info-200: #80deea;
    --info-300: #4dd0e1;
    --info-400: #26c6da;
    --info-500: #00bcd4;
    --info-600: #00acc1;
    --info-700: #0097a7;
    --info-800: #00838f;
    --info-900: #006064;

    --success-50: #ecfdf5;
    --success-100: #d1fae5;
    --success-200: #a7f3d0;
    --success-300: #6ee7b7;
    --success-400: #34d399;
    --success-500: #10b981;
    --success-600: #059669;
    --success-700: #047857;
    --success-800: #065f46;
    --success-900: #064e3b;

    --warning-50: #fffbeb;
    --warning-100: #fef9c3;
    --warning-200: #fef08a;
    --warning-300: #fde047;
    --warning-400: #facc15;
    --warning-500: #eab308;
    --warning-600: #ca8a04;
    --warning-700: #a16207;
    --warning-800: #854d0e;
    --warning-900: #713f12;

    --error-50: #fef2f2;
    --error-100: #fee2e2;
    --error-200: #fecaca;
    --error-300: #fca5a5;
    --error-400: #f87171;
    --error-500: #ef4444;
    --error-600: #dc2626;
    --error-700: #b91c1c;
    --error-800: #991b1b;
    --error-900: #7f1d1d;

    --dark-error-300: #e2a3af;
    --dark-error-400: #d98594;
    --dark-error-500: #cf6679;
    --dark-error-600: #a65261;

    --blue-grey-50: #eceff1;
    --blue-grey-100: #cfd8dc;
    --blue-grey-200: #b0bec5;
    --blue-grey-300: #b0bec5;
    --blue-grey-400: #78909c;
    --blue-grey-500: #607d8b;
    --blue-grey-600: #546e7a;
    --blue-grey-700: #455a64;
    --blue-grey-800: #37474f;
    --blue-grey-900: #263238;

    --neutral-50: #fafafa;
    --neutral-100: #f5f5f5;
    --neutral-200: #eeeeee;
    --neutral-300: #dbdbdb;
    --neutral-400: #bdbdbd;
    --neutral-500: #9e9e9e;
    --neutral-600: #757575;
    --neutral-700: #616161;
    --neutral-800: #424242;
    --neutral-900: #212121;

    --stone-50: #fafaf9;
    --stone-100: #f5f5f4;
    --stone-200: #e7e5e4;
    --stone-300: #d6d3d1;
    --stone-400: #a8a29e;

    --txt-active: rgba(0, 0, 0, 0.87);
    --txt-inactive: rgba(0, 0, 0, 0.62);
    --txt-disabled: rgba(0, 0, 0, 0.4);

    --txt-title: #000;
    --txt-subtitle-01: #272727;
    --txt-body-01: #23272f;

    --txt-info: var(--neutral-500);
    --text-dark: var(--neutral-900);
}
